import { Controller } from "@hotwired/stimulus"

class ImageThumbnailLoader {
    imageIndex = 0
    img = null

    constructor(container) {
      this.container = container
    }

    load(file) {
        var url = window.URL || window.webkitURL;
        var fileURL = url.createObjectURL(file);

        const img = this.createImgElement()
        img.src = fileURL
    }

    createImgElement() {
        const img = document.createElement('img')
        img.classList.add("rounded-xl", "w-12", "h-12", "object-cover")
        this.container.appendChild(img)
        return img
    }
}

export default class extends Controller {
    static targets = ["thumbnails", "file"]

    connect() {
        this.fileTarget.addEventListener("change", async e => {
            this.clearThumbnails()
            const files = e.target.files;
            const len = Math.min(3, files.length)
            for (let i = 0; i < len; i++) {
                new ImageThumbnailLoader(this.createThumbnailContainer()).load(files[i])
            }
        })
    }

    createThumbnailContainer() {
        const div = document.createElement('div')
        div.classList.add("flex", "items-center", "mr-2")
        this.thumbnailsTarget.appendChild(div)
        return div
    }

    clearThumbnails() {
        this.thumbnailsTarget.innerHTML = ''
    }
}
