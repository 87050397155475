import {Controller} from "@hotwired/stimulus";
import {Modal} from 'flowbite';
import RootsController from './roots_controller'
import {getCurrentBreakpoint} from "../utils/getCurrentBreakpoint";

/*
    This Controller is an alternative method to use flow-bites standard modals, but has the benefit of displaying
    automatically using JS.

    Example usage:

    <div id="my_modal_name" data-controller="flowbite-modal" data-flowbite-modal-visible="true">
        <p>This will display in modal</p>
        <button data-action="flowbite-modal#hide">Close</button>
    </div>

    <!-- show outside controller" -->
    <button data-controller="flowbite-modal" data-flowbite-modal-target="my_modal_name" data-action="flowbite-modal#show" type="button">
        Show Modal Button
    </button>


    @see https://flowbite.com/docs/components/modal/
 */
export default class FlowbiteModal extends RootsController {
    static targets = ['modal'];
    static outlets = ['flowbite-modal']
    static values = {
        placement: { type: String, default: 'center' },
        backdrop: { type: String, default: 'dynamic' },
        closable: { type: Boolean, default: true },
        backdropClasses: { type: String, default: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40' },
        eventDetail: { type: Object, default: {} },
        openDuration: { type: Number, default: 0 },
    };

    modalTargetConnected(element) {
        const placement = element.dataset.modalPlacement || this.placementValue;
        const backdrop = element.dataset.modalBackdrop || this.backdropValue;
        const closable = (typeof element.dataset.modalClosable !== 'undefined') ? element.dataset.modalClosable !== "false" : this.closableValue;
        const backdropClasses = element.dataset.backdropClasses || this.backdropClassesValue;
        const modalId = element.dataset.flowbiteModalId || element.dataset.flowbiteModalTargetId || element.id;
        let autoOpen = element.dataset.flowbiteModalVisible === "true"
        const duration = element.dataset.flowbiteModalVisibleDuration // display auto open for N seconds
        const interval = element.dataset.flowbiteModalVisibleInterval // display auto open every N seconds

        const options = {
            placement: placement,
            backdrop: backdrop,
            backdropClasses: backdropClasses,
            closable: closable,
            onHide: () => {
                this.dispatch('hide', {detail: {modalId, ...this.eventDetailValue}});
                this.debug_log(`modal id: '${modalId}' is hidden`);
            },
            onShow: () => {
                this.debug_log(`modal id: '${modalId}' is shown`);
            },
            onToggle: () => {
                this.debug_log(`modal id: '${modalId}' has been toggled`);
            },
        };

        if (options.placement === "mobile-bottom") {
            options.placement = this.setupMobileBottomPlacement();
        }

        const instanceOptions = {
            id: modalId,
            override: true
        };

        this.debug_log("Initializing Modal:", options, instanceOptions);

        this.modal = new Modal(element, options, instanceOptions);
        this.modal_id = modalId

        if(autoOpen && (interval || duration)) {
            let sessionName = `modal-${modalId}`
            let dateStr = sessionStorage.getItem(sessionName);
            let sessionDate  = dateStr && new Date(dateStr);
            let pastSeconds =  sessionDate ? (new Date() - sessionDate) / 1000 : 0
            let intervalSeconds = parseInt(interval)
            let durationSeconds = parseInt(duration)

            if(!dateStr) {
                sessionStorage.setItem(sessionName, new Date().toJSON());
            }

            this.debug_log(`Auto open interval: ${intervalSeconds} duration: ${durationSeconds} seconds: ${pastSeconds}`)

            if(intervalSeconds && pastSeconds != 0 && pastSeconds < intervalSeconds) {
                this.debug_log(`Cancelling display of modal id: '${modalId}' because past seconds of ${pastSeconds} <  interval of ${intervalSeconds} `)
                autoOpen = false
            }

            if(durationSeconds && pastSeconds > durationSeconds)   {
                autoOpen = false
                this.debug_log(`Cancelling display of modal id: '${modalId}' because past seconds of ${pastSeconds} >  duration of ${durationSeconds} `)
            }
        }

        if (autoOpen) {
            this.debug_log(`automatically showing model id: ${modalId}`);
            this.show();
        }

        if (this.openDurationValue > 0) {
            setTimeout(() => this.hide(), this.openDurationValue * 1000)
        }
    }

    /*
        This function handles showing and hiding a model, and determines if it is called from a button outside the controller,
        which will send an event to be received by the modal, or if it is the controller receiving the event and
        should perform the display action.
     */
    _modal(action, event) {
        if (this.hasFlowbiteModalOutlet) {
            this.debug_log(`Performing :${action} on modal outlet:`, this.flowbiteModalOutlet)
            this.flowbiteModalOutlet[action](event)

        } else if (this.modal) {
            this.debug_log(`Performing :${action} on modal id: ${this._instanceId}`)
            this.modal[action]();
        }
    }

    show(event) {
        this._modal('show', event);
    }

    hide(event) {
        this._modal('hide', event);
    }

    toggle(event) {
        this._modal('toggle', event);
    }

    setupMobileBottomPlacement() {
        if (["base", "xs", "sm"].indexOf(getCurrentBreakpoint()) >= 0) {
            this.element.querySelectorAll("[data-mobile-bottom-class]").forEach((el) => {
                const toRemove = el.dataset.defaultClass
                if (toRemove) {
                    toRemove.split(" ").forEach((cls) => {
                        el.classList.remove(cls)
                    })
                }
                const toAdd = el.dataset.mobileBottomClass
                if (toAdd) {
                    toAdd.split(" ").forEach((cls) => {
                        el.classList.add(cls)
                    })
                }
            })
            return "bottom-center"
        } else {
            return "center"
        }
    }
}
