import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["rating", "star"];
    static values = {activeClass: String, inactiveClass: String};

    connect() {
        this.updateStarStyle(this.ratingTarget.value);
    }

    rate(event) {
        let rate = event.target.dataset.rate;

        this.ratingTarget.value = rate;
        this.updateStarStyle(rate);
        this.dispatchChangeEvent()
    }

    dispatchChangeEvent() {
        const event = new CustomEvent("rating-input:change", {detail: {value: this.ratingTarget.value}})
        this.element.dispatchEvent(event)
    }

    updateStarStyle(amount) {
        if (amount == 0) return;

        this.starTargets.forEach((element, index) => {
            if (index < (amount)) {
                element.classList.remove(...this.inactiveClassValue.split(" "));
                element.classList.add(...this.activeClassValue.split(" "));
            } else {
                element.classList.remove(...this.activeClassValue.split(" "));
                element.classList.add(...this.inactiveClassValue.split(" "));
            }
        });
    }
}
