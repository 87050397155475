import {DirectUpload} from "./direct_upload"
import {S3MultipartBlobUpload} from "./s3_multipart_blob_upload";

export class S3MultipartUpload extends DirectUpload {
  createChecksum(cb) {
    // Don't create checksum for S3 Multipart Uploads
    cb(null, null)
  }

  setupBlobUpload(blob) {
    const upload = new S3MultipartBlobUpload(blob)
    this.cancellable = upload
    this.notifyDelegate("directUploadWillStoreFileWithXHR", upload.uppy)
    return upload
  }
}
