import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="carousel-update"
export default class extends Controller {
  static targets = [ 'update' ]

  connect() {
  }

  change(event) {
    this.updateTargets.forEach((element) => {
      element.innerHTML = event.detail.slide.dataset[element.dataset.updateKey]
    })
  }
}
