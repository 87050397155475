
let currentBreakpoint = null;

export function getCurrentBreakpoint() {
  return currentBreakpoint
}

function updatingActiveBreakpoint() {
  const breakpoints = {
    'xs': '(max-width: 639px)',
    'sm': '(min-width: 640px) and (max-width: 767px)',
    'md': '(min-width: 768px) and (max-width: 1023px)',
    'lg': '(min-width: 1024px) and (max-width: 1279px)',
    'xl': '(min-width: 1280px) and (max-width: 1535px)',
    '2xl': '(min-width: 1536px)',
  };

  for (const breakpoint in breakpoints) {
    if (window.matchMedia(breakpoints[breakpoint]).matches) {
      currentBreakpoint = breakpoint
      console.log("!> Current breakpoint: ", currentBreakpoint)
      break;
    }
  }
}

window.addEventListener('resize', updatingActiveBreakpoint);

updatingActiveBreakpoint();