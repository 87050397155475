import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="option-filters-panel"
export default class extends Controller {
  static targets = [ "option" ]

  connect() {
  }

  clear() {
    this.optionTargets.forEach((option) => {
      option.checked = false
    })
  }
}
