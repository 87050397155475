import { Controller } from "@hotwired/stimulus"
import {LockController} from "../utils/wakeLock"
import {DirectUploadsController} from "../activestorage/direct_uploads_controller"
import {
  setTextContentOnTarget,
  removeClassOnTarget,
} from "../utils/dom_utils"
import _debug from "debug"

const debug = _debug("attachment-field")

function getReadableFileSizeString(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

// Connects to data-controller="attachment-field"
export default class extends Controller {
  static targets = [
    "dropzone",
    "fileField",
    "attachedPrototype",
    "attachedContainer",
    "uploading",
    "directUploadContainer",
    "skipBtn",
    "customActionBtn"
  ]
  static values = {
    awsMultipartEnabled: {type: Boolean, default: false},
    multipartThreshold: {type: Number, default: 100 * 1024 * 1024}
  }
  static outlets = ["flowbite-modal", "attachment-field--progress-modal"]

  directUploadsController = null
  teardownDirectUploadsController = null
  prototype = null
  wakeLock = new LockController({debug: false})

  get numAttachments() {
    return Array.from(
      this.directUploadContainerTarget.querySelectorAll("input[type='hidden']")
    ).filter(input => input.value.length).length
  }

  get uploadFailureDialog() {
    return this.flowbiteModalOutlets.find((modal) => modal.element.dataset.key === "upload-failed")
  }

  get progress() {
    return this.attachmentFieldProgressModalOutlet
  }

  connect() {
    this.prototype = this.attachedPrototypeTarget
    this.prototype.remove()
    this.prototype.classList.remove("hidden")

    this.fileFieldTarget.addEventListener("direct-upload:initialize", this.directUploadInitialized.bind(this))
    this.fileFieldTarget.addEventListener("direct-upload:start", this.directUploadStarted.bind(this))
    this.fileFieldTarget.addEventListener("direct-upload:progress", this.directUploadProgress.bind(this))
    this.fileFieldTarget.addEventListener("direct-upload:error", this.directUploadError.bind(this))
    this.fileFieldTarget.addEventListener("direct-upload:end", this.directUploadEnd.bind(this))

    this.fileFieldTarget.addEventListener("direct-uploads:start", this.uploadSessionStart.bind(this))
    this.fileFieldTarget.addEventListener("direct-uploads:end", this.uploadSessionEnd.bind(this))
  }

  handleDragOver(event) {
    this.dropzoneTarget.classList.add("bg-roots-green-300")
    event.preventDefault()
    event.dataTransfer.dropEffect = 'copy'
    event.dataTransfer.effectAllowed = 'copy'
  }

  handleDrop(event) {
    event.preventDefault()
    const files = event.dataTransfer.files
    const fileInput =  this.fileFieldTarget
    this.dropzoneTarget.classList.remove("bg-roots-green-300")
    fileInput.files = files
    this.upload()
  }

  upload() {
    if (this.directUploadsController) {
      /* For now, don't do anything if an upload is currently in progress. One
       * thing I'm not sure about is what the behavior is of files that were
       * previously selected and when a new file is selected. If this isn't
       * defined in spec, then behavior could differ between different browsers.
       */
      return;
    }

    this.directUploadsController = new DirectUploadsController(
      this.fileFieldTarget,
      {
        awsMultipartEnabled: this.awsMultipartEnabledValue,
        multipartThreshold: this.multipartThresholdValue
      })

    this.fileFieldTarget.dataset.enablesValue = "false"

    this.teardownDirectUploadsController = () => {
      this.fileFieldTarget.dataset.enablesValue = "true"
      this.fileFieldTarget.value = null
      this.directUploadsController = null
    }

    this.directUploadsController.start(this.teardownDirectUploadsController)
  }

  retryUpload(_event) {
    this.uploadFailureDialog.hide()
    this.progress.show()
    this.directUploadsController.retry(this.teardownDirectUploadsController)
  }

  skipUpload(_event) {
    this.uploadFailureDialog.hide()
    this.progress.show()
    this.directUploadsController.skip(this.teardownDirectUploadsController)
  }

  cancelUpload(_event) {
    this.directUploadsController.cancel()
    this.showUploadFailureDialog("Upload Cancelled")
  }

  deleteUpload(event) {
    const upload = event.target.closest("[data-attachment-field-target='uploading']")
    const id = upload.dataset.directUploadId

    const hiddenInput = this.directUploadContainerTarget.querySelector(`input[type='hidden'][data-direct-upload-id='${id}']`)
    hiddenInput.remove()
    upload.remove()
  }

  directUploadInitialized(event) {
  }

  directUploadStarted() {
    let uploadingMsg = "Uploading..."
    if (this.directUploadsController.totalUploads > 1) {
      uploadingMsg = `Uploading ${this.directUploadsController.currentUpload} of ${this.directUploadsController.totalUploads}...`
    }
    this.progress.reset()
    this.progress.start(uploadingMsg)
  }

  directUploadProgress(event) {
    this.progress.percentProgress(event.detail.progress)
  }

  directUploadError(event) {
    debug("Upload error", event.detail.error)
    event.preventDefault()

    if (this.directUploadsController.cancelledOrFailed) {
      return
    }

    Honeybadger.notify(event.detail.error)

    this.showUploadFailureDialog("Upload Failed")
  }

  directUploadEnd(event) {
    this.progress.complete()

    const {id, file} = event.detail
    const uploading = this.prototype.cloneNode(true)
    uploading.setAttribute("data-attachment-field-target", "uploading")
    uploading.dataset.directUploadId = id
    setTextContentOnTarget(uploading, "name", file.name)
    setTextContentOnTarget(uploading, "size", getReadableFileSizeString(file.size))
    removeClassOnTarget(uploading, "size", "hidden")
    removeClassOnTarget(uploading, "deletebtn", "hidden")
    this.attachedContainerTarget.appendChild(uploading)
  }

  uploadSessionStart(_event) {
    debug("Upload session started")
    this.progress.show()
  }

  uploadSessionEnd(_event) {
    debug("Upload session ended")
    this.progress.hide()
    this.wakeLock.release()
  }

  getAttachedUpload(id) {
    const found = this.uploadingTargets.find(uploading => uploading.dataset.directUploadId == id)
    if (!found) {
      throw new Error(`Upload entry not found with id: ${id}`)
    }
    return found
  }

  submitForm() {
    this.uploadFailureDialog.hide()
    this.element.closest("form").requestSubmit()
  }

  showUploadFailureDialog(title) {
    if (!this.hasCustomActionBtnTarget) {
      this.skipBtnTarget.classList.remove("hidden")
    } else if (this.directUploadsController.uploads.length) {
      this.customActionBtnTarget.classList.add("hidden")
      this.skipBtnTarget.classList.remove("hidden")
    } else {
      this.customActionBtnTarget.classList.remove("hidden")
      this.skipBtnTarget.classList.add("hidden")
    }
    setTextContentOnTarget(this.uploadFailureDialog.element, "title", title)
    this.progress.hide()
    this.uploadFailureDialog.show()
  }
}
