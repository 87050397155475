import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="checkbox-group"
export default class extends Controller {
  static targets = [ "checkbox" ]

  connect() {
  }

  updateCheckboxes(event) {
    if (this.checkboxTargets.length == 2 && !event.target.checked) {
      event.target.checked = true
    }

    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox != event.target) {
        if (checkbox.checked) {
          checkbox.checked = false
        }
      }
    })
  }
}
