import {dispatchEvent} from './helpers'
import {DirectUploadController} from "./direct_upload_controller"
import {S3MultipartUploadController} from "./s3_multipart_upload_controller"
import _debug from 'debug'

const debug = _debug('direct_uploads_controller')

class DummyUploadController extends DirectUploadController {
  start(callback) {
    this.dispatch("start")
    setTimeout(() => {
      const error = new Error("BOOM!")
      callback(error)
      this.dispatch("error", {error: error})
    }, 2000)
  }
}

export class DirectUploadsController {
  uploads = []
  uploadInProgress = false
  totalUploads = 0
  currentUpload = 0
  currentUploadController = null
  cancelledOrFailed = false

  constructor(fileInput, options={}) {
    this.fileInput = fileInput
    this.awsMultipartEnabled = !!options.awsMultipartEnabled
    this.multipartThreshold = options.multipartThreshold || 100 * 1024 * 1024
  }

  start(callback) {
    if (this.uploadInProgress) {
        // there's already uploads in progress
        return
    }

    this.uploadInProgress = true
    this.createDirectUploadControllers()
    this.totalUploads = this.uploads.length
    this.cancelledOrFailed = false

    debug("Starting direct uploads", this.uploads)
    this.dispatch("start")
    this.startNextController(callback)
  }

  retry(callback) {
    this.cancelledOrFailed = false
    this.currentUploadController = this.createDirectUploadController(this.currentUploadController.file)
    debug("Retrying upload", this.currentUploadController)
    this.startController(callback)
  }

  skip(callback) {
    this.cancelledOrFailed = false
    this.startNextController(callback)
  }

  cancel() {
    this.cancelledOrFailed = true
    this.currentUploadController.cancel()
  }

  startController(callback) {
    this.currentUploadController.start((error) => {
      if (error) {
        this.cancelledOrFailed = true
      }
      if (!this.cancelledOrFailed) {
        this.startNextController(callback)
      }
    })
  }

  startNextController(callback) {
    this.currentUploadController = this.uploads.shift()
    if (this.currentUploadController) {
      this.currentUpload += 1
      this.startController(callback)
    } else {
      debug("All uploads complete")
      callback()
      this.dispatch("end")
    }
  }

  createDirectUploadControllers() {
    Array.from(this.fileInput.files).forEach(file => {
      let controller = this.createDirectUploadController(file)
      this.uploads.push(controller)
    })
  }

  createDirectUploadController(file) {
    if (this.shouldUseMultipartUpload(file)) {
      return new S3MultipartUploadController(this.fileInput, file)
    } else {
      return new DirectUploadController(this.fileInput, file)
    }
  }

  shouldUseMultipartUpload(file) {
    return this.awsMultipartEnabled && file.size >= this.multipartThreshold
  }

  dispatch(name, detail = {}) {
    return dispatchEvent(this.fileInput, `direct-uploads:${name}`, {detail})
  }
}