import Uppy from "@uppy/core"
import AwsS3 from "@uppy/aws-s3"
import {getMetaValue} from "./helpers"

export class S3MultipartBlobUpload {
  constructor(blob) {
    this.blob = blob
    this.file = blob.file

    this.uppy = new Uppy({
      debug: false,
      autoProceed: false
    })
    this.uppy.use(AwsS3, {
      endpoint: "/",
      shouldUseMultipart() { return true },
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      }
    })
  }

  create(callback) {
    this.uppy.addFile({
      source: "active_storage",
      name: this.file.name,
      type: this.file.type,
      data: this.file,
      meta: { signed_id: this.blob.attributes.signed_id }
    })
    this.uppy.upload().then((result) => {
      if (this.cancelled || result.successful.length) {
        callback()
      } else {
        callback(result.failed[0].error)
      }
    })
  }

  cancel() {
    this.cancelled = true
    this.uppy.cancelAll()
  }
}