import { Controller } from "@hotwired/stimulus"


export default class extends Controller {

  close() {
    if (this.element) {
      this.element.classList.add('animate-fadeOutLeft')
      this.element.classList.add('opacity-0') 
      setTimeout(() => this.element.remove(), 0.5 * 1000) // remove from dom after 1/2 second
    }
  }
}
  