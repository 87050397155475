import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="trigger"
export default class extends Controller {
  static outlets = [ "flowbite-modal" ]

  connect() {

  }

  trigger(event) {
    event.preventDefault();
    this.flowbiteModalOutlet.show()
  }
}
