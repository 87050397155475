import { Controller } from "@hotwired/stimulus"
import ProgressBar from 'progressbar.js'

// Connects to data-controller="circular-progress"
export default class extends Controller {
  bar = null
  connect() {
    this.bar = new ProgressBar.Circle(
      this.element,
      {
        strokeWidth: 8,
        trailWidth: 8,
        easing: 'easeInOut',
        duration: 1400,
        text: {
          autoStyleContainer: false,
          className: "text-[#0b0b0b] text-base font-normal font-inter"
        },
        from: { color: '#046262', width: 8 },
        to: { color: '#046262', width: 8 },

        step: function(state, circle) {
          circle.path.setAttribute("stroke", state.color);
          circle.path.setAttribute("stroke-width", state.width);
          circle.path.setAttribute("stroke-linecap", "round");

          const value = Math.round(circle.value() * 100);
          if (value === 0) {
            circle.setText('');
          } else {
            circle.setText(`${value}%`);
          }
        }
    });

    // this.bar.animate(1.0);  // Number from 0.0 to 1.0
  }

  percentProgress(num) {
    // this.bar.animate(num/100)
    this.bar.set(num/100)
  }

  stopProgress() {
    this.bar.stop()
  }

  resetProgress() {
    this.bar.set(0)
  }
}
