import { Controller } from "@hotwired/stimulus"

function log(...args) {
  console.log("[DEBUG]", ...args)
}

// Connects to data-controller="debug"
export default class extends Controller {
  static values = { mutations: String }

  connect() {
    log("connected")

    if (this.hasMutationsValue) {
      log("observing mutations for:", this.mutationsValue)
      const targetAttributes = this.mutationsValue.split(" ")
      const observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          // Check the modified attributeName is "disabled"
          if(targetAttributes.includes(mutation.attributeName) ) {
            log("Attribute changed:", mutation.attributeName, mutation.target[mutation.attributeName])
          }
        });    
      });
      observer.observe(this.element, { attributes: true });
    }
  }

  debugAction(event) {
    log("action fired:", event)
  }
}
