import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="list-filters"
export default class extends Controller {
  static outlets = [ "reveal" ]
  static targets = [ "form" ]

  connect() {
  }

  toggle() {
    this.revealOutlet.toggle()
  }

  hide() {
    if (this.visible) {
      this.toggle()
    }
  }

  show() {
    if (!this.visible) {
      this.toggle()
    }
  }

  get visible() {
    const item = this.revealOutlet.itemTargets.find(item => !item.classList.contains("hidden"))
    return item.querySelector("#filters")
  }

  get formData() {
    const detail = new FormData(this.formTarget)
    detail.delete("authenticity_token")
    return detail
  }

  apply() {
    this.element.dispatchEvent(new CustomEvent("list-filters:changed", { bubbles: true, detail: this.formData }))
  }

  clear() {
    this.element.dispatchEvent(new CustomEvent("list-filters:changed", { bubbles: true, detail: new FormData() }))
  }
}
