import { Application } from "@hotwired/stimulus"

const application = Application.start()

application.handleError = (error, message, detail) => {
  console.warn(message, detail)
  Honeybadger.notify(error)
}

// Configure Stimulus development experience
application.debug    = false
window.Stimulus      = application

export { application }
