/* eslint no-console:0 */

import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

require("local-time").start()

import "./channels"
import "./controllers"
import "./src/**/*"

import "flowbite/dist/flowbite.turbo.js"
import TurboPower from "turbo_power"

TurboPower.initialize(Turbo.StreamActions)
