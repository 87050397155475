import { Controller } from "@hotwired/stimulus"
import VideoThumbnails from "../utils/videoThumbnails";

class VideoThumbnailLoader {
  imageIndex = 0
  img = null
  
  constructor(container) {
    this.container = container
  }
  
  load(file) {
    const thumbnail = new VideoThumbnails({
      count: 4,
    })
    thumbnail.on('capture', (image) => {
      // imageIndex 1 is all black, so grab index = 2 to be sure to get an image.
      if (this.imageIndex === 2) {
        const img = this.createImgElement()
        img.src = image
        thumbnail.abort()
      }
      this.imageIndex += 1
    })
    thumbnail.on('unsupported', () => {
      console.log('video unsupported')
      this.createGenericThumbnail()
    })
    try {
      thumbnail.capture(file)
    } catch (e) {
      this.createGenericThumbnail()
    }
  }
  
  createImgElement() {
    const img = document.createElement('img')
    img.classList.add("rounded-xl", "w-12", "h-12", "object-cover")
    this.container.appendChild(img)
    return img
  }
  
  createGenericThumbnail() {
    this.container.innerHTML = `
          <div class="w-12 h-12 bg-gray-400 rounded-xl flex items-center justify-center">
            <svg class="w-6 h-6 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 6H4a1 1 0 0 0-1 1v10c0 .6.4 1 1 1h10c.6 0 1-.4 1-1V7c0-.6-.4-1-1-1Zm7 11-6-2V9l6-2v10Z"/>
            </svg>
          </div>
        `
  }
}

export default class extends Controller {
  static targets = ["videoThumbnail", "videoField","videodropzone"]
  
  connect() {
    this.videoFieldTarget.addEventListener("change", async e => {
      this.clearThumbnails()
      const files = e.target.files;
      this.updateThumbnails(files);
    })
  }
  
  updateThumbnails(files) {
    const len = Math.min(3, files.length)
    for (let i = 0; i < len; i++) {
      new VideoThumbnailLoader(this.createThumbnailContainer()).load(files[i])
    }
  }
  
  handleDragOver(event) {
    this.videodropzoneTarget.classList.add("bg-roots-green-300");
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
    event.dataTransfer.effectAllowed = 'copy';
    
  }
  
  handleDragOff(event) {
    this.videodropzoneTarget.classList.remove("bg-roots-green-300");
    event.preventDefault();
  }
  
  handleDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const fileInput =  this.videoFieldTarget;
    this.videodropzoneTarget.classList.remove("bg-roots-green-300");
    fileInput.files = files;
    this.updateThumbnails(files);
    this.element.scrollIntoView();
  }
  
  
  createThumbnailContainer() {
    const div = document.createElement('div')
    div.classList.add("flex", "items-center", "mr-2")
    div.setAttribute("data-new", "true")
    this.videoThumbnailTarget.appendChild(div)
    return div
  }
  
  clearThumbnails() {
    this.videoThumbnailTarget.querySelectorAll('div[data-new="true"]').forEach((el) => {
      el.remove()
    })
  }
}
