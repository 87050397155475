import { Controller } from "@hotwired/stimulus";
import Swiper from "swiper";
import { Navigation, Pagination } from 'swiper/modules';

export default class extends Controller {
  static targets = ['swiper', 'pagination', 'radio', 'submitbtn', 'slide'];
  static values = {
    slidesPerView: { type: Number, default: 1 },
    navigation: { type: Boolean, default: true },
  }

  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      modules: [Pagination, Navigation],
      slidesPerView: this.slidesPerViewValue,
      spaceBetween: 8,
      centeredSlides: true,
      direction: 'horizontal',

      pagination: {
        el: '.swiper-pagination',
      },

      navigation: {
        enabled: this.navigationValue,
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    this.swiper.on('slideChange', this.handleSwipe.bind(this));
  }

  handleSwipe(_event) {
    this.element.dispatchEvent(new CustomEvent('carousel:change', { bubbles: true, detail: { slide: this.slideTargets[this.swiper.realIndex] } }));
  }
}
