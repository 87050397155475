import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    /*
        This function conditionally logs to console if controller debug option is enabled.
        <div data-controllerName-debug='true' > </div>
     */
    debug_log(...args) {
        if(this.debug) {
            console.log(`${this.controllerName}:`, ...args);
        }
    }

    get debug() {
        const debugAttribute = `${this.controllerName}Debug`
        const debug = this.element.dataset[debugAttribute]

        return ['1','true'].includes(debug)
    }

    get controllerName() {
        return this.context.identifier
    }
}
