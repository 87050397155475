import {DirectUploadController} from "./direct_upload_controller"
import {S3MultipartUpload} from "./s3_multipart_upload"

export class S3MultipartUploadController extends DirectUploadController {
  createDirectUpload() {
    return new S3MultipartUpload(this.file, this.url, this)
  }

  uppyUploadRequestDidProgress = (progress) => {
    this.uploadRequestDidProgress({ loaded: progress, total: 100 })
  }

  directUploadWillStoreFileWithXHR(uppy) {
    this.dispatch("before-storage-request", {
      uppy: uppy
    });
    this.uppy = uppy
    uppy.on("progress", this.uppyUploadRequestDidProgress)
  }

  cancel() {
    if (this.uppy) {
      this.uppy.off("progress", this.uppyUploadRequestDidProgress)
    }
    super.cancel()
  }
}