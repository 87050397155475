import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["menu"]
  static values = {
    id: String
  }

  connect() {
    // Using dataset to store open state
    this.element.dataset.open = "false"
    document.addEventListener("click", this.closeOnClickOutside.bind(this))
  }

  disconnect() {
    document.removeEventListener("click", this.closeOnClickOutside.bind(this))
  }

  submit(event) {
    event.target.form.requestSubmit()
    this.hideMenu()
  }

  toggle(event) {
    event.stopPropagation()
    const menu = this.menuTarget
    const isOpen = this.element.dataset.open === "true"

    if (isOpen) {
      this.close(menu)
    } else {
      // Close all other dropdowns first
      document.querySelectorAll(`[data-date-filter-dropdown-id-value]:not([data-date-filter-dropdown-id-value="${this.idValue}"])`).forEach(dropdown => {
        if (dropdown.dataset.open === "true") {
          const otherMenu = dropdown.querySelector('[data-date-filter-dropdown-target="menu"]')
          this.close(otherMenu)
          dropdown.dataset.open = "false"
        }
      })
      
      this.open(menu)
    }
    
    this.element.dataset.open = (!isOpen).toString()
  }

  closeOnClickOutside(event) {
    if (!this.element.contains(event.target) && this.element.dataset.open === "true") {
      this.close(this.menuTarget)
      this.element.dataset.open = "false"
    }
  }

  open(menu) {
    menu.classList.remove('hidden')
    
  }

  close(menu) {
    menu.classList.add('hidden')
  }
}