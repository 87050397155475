import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "show", "edit", "form", "nameInput", "amountInput", "saving", "addBtn" ]
  static values = {
    name: String,
    amount: Number
  }

  get newAmountValue() {
    return parseFloat(this.amountInputTarget.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'))
  }

  // reveal the editor
  edit() {
    this.editTarget.classList.remove("hidden")
    this.showTarget.classList.add("hidden")
    this.nameInputTarget.focus()
  }

  // reveal the pretty line item
  show() {
    this.editTarget.classList.add("hidden")
    this.showTarget.classList.remove("hidden")
  }

  // reveal spinner
  showSaving() {
    this.editTarget.classList.add("hidden")
    this.savingTarget.classList.remove("hidden")
  }

  save() {
    if (this.saving) return

    this.saving = true
    this.showSaving()
    this.formTarget.requestSubmit() // goes through the Rails form submission machinery
  }

  // submit or hide the editor

  validateCurrency() {
    this.amountInputTarget.value = new String(this.newAmountValue)
  }

  cancelEditIfUnchanged() {
    const
      oldName = this.nameValue,
      newName = this.nameInputTarget.value,
      oldAmount = this.amountValue,
      newAmount = this.newAmountValue,
      changed = (oldName !== newName) || (oldAmount !== newAmount);

    if(!changed && newName.length > 0 && newAmount > 0) {
      this.show()
    }
  }

  handleBlur(event) {
    if (this.element.contains(event.relatedTarget)) return

    const
      oldName = this.nameValue,
      newName = this.nameInputTarget.value,
      oldAmount = this.amountValue,
      newAmount = this.newAmountValue,
      changed = (oldName !== newName) || (oldAmount !== newAmount)

    /* if inputs are blank, stay in edit mode */
    if (newName.length === 0 || newAmount === 0) {
      this.edit()

    /* if inputs have changed, submit the form */
    } else if (changed) {
      this.save()

    /* if no changes and focus is lost, exit edit mode */
    } else {
      this.show()
    }
  }

  addLineItem(_event) {
    const
      oldName = this.nameValue,
      newName = this.nameInputTarget.value,
      oldAmount = this.amountValue,
      newAmount = this.newAmountValue,
      changed = (oldName !== newName) || (oldAmount !== newAmount)

    if (newName.length === 0 || newAmount === 0) {
      this.edit()
      return
    }

    const input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", "add_line_item")
    input.setAttribute("value", "1")
    this.formTarget.appendChild(input)

    this.save()
  }
}
