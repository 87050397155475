import { Controller } from "@hotwired/stimulus"
import { received } from "../channels/onboarding_channel"

// Connects to data-controller="onboarding-channel"
export default class extends Controller {
  connect() {
    received((data) => {
      if (data.event) {
        this.element.dispatchEvent(new CustomEvent(`onboarding:${data.event}`, { detail: data }))
      }
    })
  }
}
