export class LockController {
  lock = null
  log = console.log

  constructor(options = {}) {
    this.lock = null
    this.log = options.debug ? console.log : () => {}
  }

  async request() {
    if (!('wakeLock' in navigator)) {
      this.log("[wakeLock] Requesting wake lock...")
      return
    }

    try {
      this.log("[wakeLock] Requesting wake lock...")
      this.lock = await navigator.wakeLock.request('screen')
      this.log("[wakeLock] Wake lock acquired")
      document.addEventListener("visibilitychange", this.reacquireWakeLock)
    } catch (err) {
      console.error(`${err.name}, ${err.message}`)
    }
  }

  async release() {
    if (this.lock) {
      this.log("[wakeLock] Releasing wake lock...")
      await this.lock.release()
      this.lock = null
      this.log("[wakeLock] Wake lock released")
    } else {
      this.log("[wakeLock] No wait lock to release")
    }
  }

  reacquireWakeLock = async () => {
    try {
      this.log("[wakeLock] Reacquiring wake lock...")
      wakeLock = await navigator.wakeLock.request('screen')
      this.log("[wakeLock] Wake lock reacquired")
    } catch (err) {
      console.error(`${err.name}, ${err.message}`)
    }
  }
}
