import RootsController from './roots_controller'

/* This simulates the default flowbite tab behavior that executed by default if `data-tabs-toggle` is present.
   B/c we can't override styles on aforesaid default execution, we use targets instead and populate/toggle between selected
   styles using stimulus values.

   @see: https://github.com/themesberg/flowbite/blob/main/src/components/tabs/index.ts
*/
export default class extends RootsController {
    static targets = ['tablist', 'tab', 'input', 'title']
    static values = {
        activeClasses: {
            type: String,
            default: 'text-blue-600 border-blue-600 hover:text-blue-600'
        },
        inactiveClasses: {
            type: String,
            default: 'text-gray-500 border-gray-100 hover:text-gray-600 hover:border-gray-300',
        }
    }

    connect() {
        let defaultTabId = null
        this.tabsItems = this.tabTargets.map(currentTab => {
            const tabContentId = currentTab.getAttribute('aria-controls') 

            if (currentTab.getAttribute('aria-selected') === 'true') {
                defaultTabId = currentTab.getAttribute('aria-controls')
            }

            return {
                id: tabContentId,
                triggerEl: currentTab,
                targetEl: document.querySelector(`#${tabContentId}`),
            }
        })

        this.tabConfig = {
            defaultTabId,
            activeClasses: this.activeClassesValue,
            inactiveClasses: this.inactiveClassesValue,
            onShow: this.onShow.bind(this),
        }

        new Tabs(this.tablistTarget, this.tabsItems, this.tabConfig)
    }

    /*
        This callback is executed page everytime a tab is selected, including initial page load.
        Herein, we assign any :input targets  any :value specified when the component was defined, or
        the tabs :id. This allows tabs to serve as a radio button for any input element.
     */
    onShow(source, cfg) {
        let element = cfg?.triggerEl
        let inputValue = element?.dataset["tabComponentValue"]
        let tabTitle = element?.dataset.tabTitle

        this.debug_log(`onShow: tab.id="${element?.id}" tab.value="${inputValue}" `)

        this.inputTargets.forEach(currentInput => {
            if(inputValue != undefined) {
                currentInput.value = inputValue
                this.debug_log(`onShow: assigning input.id="${currentInput.id}" tab.value="${inputValue}" `)
            }
        });

        if (tabTitle != undefined && this.titleTarget != undefined) {
            this.titleTarget.innerHTML = tabTitle;
        }
    }
}
