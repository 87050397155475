import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["player", "cover"];
    static values = { playFullscreen: { type: Boolean, default: false } };

    onFullscreenChange = () => {
        this.coverTarget.classList.remove("hidden")
        this.playerTarget.pause()

        document.removeEventListener("fullscreenchange", this.onFullscreenChange, false);
        document.removeEventListener("mozfullscreenchange", this.onFullscreenChange, false);
        document.removeEventListener("webkitfullscreenchange", this.onFullscreenChange, false);
    }

    connect() {
        this.hideControls();

        this.boundShowControls = this.showControls.bind(this);
        this.playerTarget.addEventListener("playing", this.boundShowControls);
    }

    hideControls() {
        this.playerTarget.controls = false;
    };

    showControls() {
        this.playerTarget.controls = true;
    };

    playPause() {
        if (this.playerTarget.paused) {
            if (this.hasCoverTarget) {
                this.coverTarget.classList.add("hidden")
            }
            if (this.playFullscreenValue) {
                this.requestFullscreen()
            }
            this.playerTarget.play()
        } else {
            this.playerTarget.pause()
        }
    }

    requestFullscreen() {
        if (this.playerTarget.requestFullscreen) {
            this.playerTarget.requestFullscreen()
        } else if (this.playerTarget.mozRequestFullScreen) {
            this.playerTarget.mozRequestFullScreen()
        } else if (this.playerTarget.webkitRequestFullscreen) {
            this.playerTarget.webkitRequestFullscreen()
        } else if (this.playerTarget.msRequestFullscreen) {
            this.playerTarget.msRequestFullscreen()
        }

        document.addEventListener("fullscreenchange", this.onFullscreenChange, false);
        document.addEventListener("mozfullscreenchange", this.onFullscreenChange, false);
        document.addEventListener("webkitfullscreenchange", this.onFullscreenChange, false);
    }
}
