import { FileChecksum } from "./file_checksum"
import { BlobRecord } from "./blob_record"
import { BlobUpload } from "./blob_upload"
import _debug from "debug"

const debug = _debug("direct_upload")

let id = 0

// Modified version of class in @rails/activestorage to allow for extension and cancelling
export class DirectUpload {
  constructor(file, url, delegate, customHeaders = {}) {
    this.id = ++id
    this.file = file
    this.url = url
    this.delegate = delegate
    this.customHeaders = customHeaders
    this.cancelled = false
  }

  create(callback) {
    debug("Calculating checksum")
    this.createChecksum((error, checksum) => {
      debug("Checksum calculated", checksum, error)
      if (this.cancelled || error) {
        debug("Cancelled or errored in checksum", error, this.cancelled)
        callback(error)
        return
      }

      debug("Creating blob record")
      const blob = this.setupBlobRecord(checksum)
      blob.create(error => {
        debug("Blob record created", blob.toJSON(), error)
        if (this.cancelled || error) {
          debug("Cancelled or errored in blob creation", error, this.cancelled)
          callback(error)
        } else {

          debug("Uploading blob")
          const upload = this.setupBlobUpload(blob)
          upload.create(error => {
            debug("Blob uploaded", error)
            if (this.cancelled || error) {
              debug("Cancelled or errored in blob upload", error, this.cancelled)
              callback(error)

            } else {
              debug("done", blob.toJSON())
              callback(null, blob.toJSON())
            }
          })
        }
      })
    })
  }

  cancel() {
    this.cancelled = true
    if (this.cancellable) {
      debug("Cancelling", this.cancellable)
      this.cancellable.cancel()
    }
  }

  createChecksum(cb) {
    return FileChecksum.create(this.file, cb)
  }

  setupBlobRecord(checksum) {
    const blob = new BlobRecord(this.file, checksum, this.url, this.customHeaders)
    this.cancellable = blob
    this.notifyDelegate("directUploadWillCreateBlobWithXHR", blob.xhr)
    return blob
  }

  setupBlobUpload(blob) {
    const upload = new BlobUpload(blob)
    this.cancellable = upload
    this.notifyDelegate("directUploadWillStoreFileWithXHR", upload.xhr)
    return upload
  }

  notifyDelegate(methodName, ...messages) {
    if (this.delegate && typeof this.delegate[methodName] == "function") {
      return this.delegate[methodName](...messages)
    }
  }
}
