import { Controller } from "@hotwired/stimulus"
import IMask from 'imask';

export default class extends Controller {
  static targets = ["ssn", "ssnbutton", "secureIcon", "unsecureIcon", "unmaskedField"]
  
  ssnMask = {};
  masked = true;

  onAccept = () => {
    this.unmaskedFieldTarget.value = this.ssnMask.unmaskedValue;
  }
  
  connect() {
    const input = this.ssnTarget;
    this.ssnMask = new IMask(input, {
      mask: '000-00-0000',
      displayChar: '*',
      lazy: false,
      overwrite: 'shift',
    });
    this.ssnMask.on('accept', this.onAccept);
    this.setButtonIcon();
  }

  disconnect() {
    this.ssnMask.off('accept', this.onAccept);
  }

  shownumber() {
    this.masked = !this.masked;
    this.ssnMask.updateOptions({
      displayChar: this.masked ? '*' : '',
    });
    this.setButtonIcon();
  }

  setButtonIcon() {
    if (this.masked) {
      this.secureIconTarget.classList.remove('hidden');
      this.unsecureIconTarget.classList.add('hidden');
    } else {
      this.secureIconTarget.classList.add('hidden');
      this.unsecureIconTarget.classList.remove('hidden');
    }
  }
}
