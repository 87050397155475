import { Controller } from "@hotwired/stimulus"

function fieldFilter(field) {
  return element => element.dataset.errorFor === field
}

function applyClasses(element, hasErrors) {
  const errorClass = element.dataset.errorClass
  if (errorClass) {
    errorClass.split(' ').forEach(className =>
      hasErrors ? element.classList.add(className) : element.classList.remove(className))
  }

  const validClass = element.dataset.validClass
  if (validClass) {
    validClass.split(' ').forEach(className =>
      hasErrors ? element.classList.remove(className) : element.classList.add(className))
  }
}

// Connects to data-controller="errors"
export default class extends Controller {
  static targets = ["render", "message"]

  connect() {
  }

  clearErrors() {
    if (this.hasRenderTarget) {
      this.renderTargets.forEach(element => applyClasses(element, false))
    }
    if (this.hasMessageTarget) {
      this.messageTargets.forEach(element => {
        applyClasses(element, false)
        element.textContent = ''
      });
    }
  }

  showError(field, message) {
    if (this.hasRenderTarget) {
      this.renderTargets.filter(fieldFilter(field)).forEach(element => {
        applyClasses(element, true)
      });
    }
    if (this.hasMessageTarget) {
      this.messageTargets.filter(fieldFilter(field)).forEach(element => {
        applyClasses(element, true)
        element.textContent = message
      });
    }
  }
}
