import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js';

export default class extends Controller {
    static targets = ['acceptance', 'download', 'enable']
    static values = {
        delay: {type: Number, default: 1000}, // how long to wait before polling starts
        interval: {type: Number, default: 3000}, // how long to wait before retrying
        rest: {type: Number, default: 500}, // how long to wait after successful url obtained to allow download
        attempts: {type: Number, default: 3},
    }
    static outlets = ['flowbite-modal']

    initialize() {
        this.performDownloadEvent = this.performDownload.bind(this);
        this.pollTimer = {}
        this.downloadCount = 0
        requestAnimationFrame(() => {
            this.toggleAcceptanceCheckbox(true)
            if (this.downloadCount == 0) {
                this.toggleAcceptanceCheckbox(false)
            }
        })
    }

    connect() {
        if (!this.downloadTargets.length) {
            this.flowbiteModalOutlet.hide()
        }
    }

    toggleAcceptanceCheckbox(status) {
        if (!this.enableTargets) return;

        this.enableTargets.forEach(el => {
            el.disabled = status
            el.style.cursor = status ? "not-allowed" : "default"
            el.style.opacity = status ? "0.2" : "1"
        });
    }

    downloadTargetConnected(el) {
        el.dataset['signpaperworkInitialLabel'] = el.innerHTML
        el.dataset['signpaperworkState'] = 'pending'; // pending, polling, failed, ready
        el.dataset['signpaperworkUrl'] = el.href;
        el.dataset['signpaperworkAttempted'] = '0';

        el.addEventListener("click", this.performDownloadEvent, false);

        this.debug_log(`downloadTargetConnected -> delaying check for ${this.delayValue} ms`)

        this.displayProcessLabel(el);

        this.downloadCount += 1

        setTimeout(() => {
            this.beginPolling(el);
        }, this.delayValue);
    }

    downloadTargetDisconnected(el) {
        el.removeEventListener("click", this.performDownloadEvent);
        this.stopPolling(el)
    }

    acceptanceTargetConnected() {
        // attach handlers
    }

    performDownload(ev) {
        const target = ev.target.closest('a')
        let state = target.dataset['signpaperworkState'];

        if (state != 'ready') {
            ev.preventDefault()
        }

        if (state === 'failed') {
            // restart download polling
            this.beginPolling(target);
        }
    }

    beginPolling(el) {
        const timerKey = this.downloadKey(el)

        if (![undefined, null].includes(this.pollTimer[timerKey])) {
            this.debug_log(`beginPolling -> exiting key ${timerKey}`);
            return
        }

        el.dataset['signpaperworkState'] = 'polling';

        this.displayProcessLabel(el);

        this.pollTimer[timerKey] = setInterval(() => {
            const attempted = parseInt(el.dataset['signpaperworkAttempted'] || '0')
            const attempt = attempted + 1

            el.dataset['signpaperworkAttempted'] = attempt;

            this.debug_log(`beginPolling -> polling attempt: ${attempt} of index: ${this.attemptsValue}`);

            if (this.attemptsValue >= attempt) {
                this.checkDownloadStatus(el);
            } else {
                this.stopPolling(el)

                if (el.dataset['signpaperworkState'] !== 'ready') {
                    this.failDownload(el)
                }
            }
        }, this.intervalValue)
    }

    stopPolling(el) {
        const timerKey = this.downloadKey(el)
        let timer = this.pollTimer[timerKey]

        if (![undefined, null].includes(timer)) {
            this.pollTimer[timerKey] = null
            clearInterval(timer)
        }
    }

    async checkDownloadStatus(el) {
        try {
            const response = await get(el.href, { responseKind: 'json' })
            let jsonResponse = null
            if (response.ok) {
                jsonResponse = await response.json;
            } else if (response.statusCode === 404) {
                throw Error('checkDownloadStatus -> received status code == 404');
            } else if (response.statusCode) {
                throw Error(`Unexpected download response: ${response.statusCode}`);
            } else {
                throw Error(`Unexpected download response: ${response}`);
            }

            let url = jsonResponse.url;
            let status = jsonResponse.status;

            if (url) {
                el.href = url;
                this.stopPolling(el);
                setTimeout(() => {
                    this.enableDownload(el);
                    this.debug_log(`checkDownloadStatus -> enabling button with url: ${url} after rest of ${this.restValue}ms`)
                    this.flowbiteModalOutlet.hide()
                }, this.restValue);

            } else if (status === 'pending') {
                this.debug_log(`checkDownloadStatus -> status still :pending`)
            }
        } catch (error) {
            // Handle the error
            this.debug_log(`checkDownloadStatus -> error:`, error);
        }
    }

    downloadKey(el) {
        const key = el.dataset['signpaperworkUrl'] || el.href;
        return key;
    }

    enableDownload(el) {
        this.downloadCount -= 1
        el.dataset['signpaperworkState'] = 'ready';
        this.displayInitialLabel(el)
        if (!this.downloadCount) {
            this.toggleAcceptanceCheckbox(false)
        }
    }

    displayProcessLabel(el) {
        let processLabel = el.dataset['signpaperworkProcessLabel'] || "Processing..."
        el.innerHTML = processLabel
        this.debug_log(`displayProcessLabel -> changed download button to label '${processLabel}'`)
    }

    displayInitialLabel(el) {
        let processLabel = el.dataset['signpaperworkInitialLabel'] || "Processing..."
        el.innerHTML = processLabel
        this.debug_log(`displayInitialLabel -> changed download button to label '${processLabel}'`)
    }

    failDownload(el) {
        let failedHTML = el.dataset['signpaperworkFailedLabel'] || el.dataset['signpaperworkInitialLabel']
        el.dataset['signpaperworkState'] = 'failed'
        el.dataset['signpaperworkAttempted'] = '0'
        el.innerHTML = failedHTML
    }


    // Below is from roots_controller.js

    debug_log() {
        const debugAttribute = `${this.controllerName}Debug`
        const debug = this.element.dataset[debugAttribute]

        if(['1','true'].includes(debug)) {
            console.log(`${this.controllerName}:`, ...arguments);
        }
    }

    get controllerName() {
        return this.context.identifier
    }
}
