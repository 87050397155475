import { Controller } from "@hotwired/stimulus"
import { Dismiss } from "flowbite"

// Connects to data-controller="toaster"
export default class extends Controller {
  connect() {
  }

  pushToast(event) {
    const div = document.createElement("div")
    div.innerHTML = event.detail.html
    this.element.appendChild(div)

    setTimeout(() => {
      const dismiss = new Dismiss(div)
      dismiss.hide()
    }, 7000)
  }
}
