import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="after-modal"
export default class extends Controller {
  connect() {
  }

  visit(event) {
    if (event.params.modal === event.detail.modalId) {
      Turbo.visit(event.params.url, event.params.visitOptions || {}) 
    }
  }
}
