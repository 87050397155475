import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="maintenance--inspection-issue-form"
export default class extends Controller {
  static targets = ["repairAmount", "residentChargeAmount", "normalWearAndTear"]

  connect() {
  }

  updateAmountValues(event) {
    if (this.normalWearAndTearTarget.checked && event.target === this.normalWearAndTearTarget) {
      this.residentChargeAmountTarget.value = "0.00"
    }

    if (this.residentChargeAmountTarget.value !== "0.00" && event.target !== this.normalWearAndTearTarget) {
      const repairTotal = parseFloat(this.repairAmountTarget.value)
      const resCharge = parseFloat(this.residentChargeAmountTarget.value)

      if (resCharge > repairTotal) {
        this.repairAmountTarget.value = this.residentChargeAmountTarget.value
      }

      if (this.normalWearAndTearTarget.checked) {
        this.normalWearAndTearTarget.checked = false
      }
    }
  }
}
