import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="intercom"
export default class extends Controller {
  connect() {
  }

  show(event) {
    if ("Intercom" in window) {
      Intercom("show")
      event.stopPropagation()
      event.preventDefault()
    }
    return true
  }
}
