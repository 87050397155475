import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "password", "charlen", "numcount", "letcount", "symcount" ]

  lengthRegex = /.{12,}/
  numberRegex = /[0-9]/
  letterRegex = /[a-zA-Z]/
  symbolRegex = /[!@#$%^&*(),.?":{}|<>]/

  connect() {
    debugger
    this.toggleChecks()
  }

  toggleChecks(event) {
    const password = this.passwordTarget.value

    this.charlenTarget.checked  = this.lengthRegex.test(password)
    this.numcountTarget.checked = this.numberRegex.test(password)
    this.letcountTarget.checked = this.letterRegex.test(password)
    this.symcountTarget.checked = this.symbolRegex.test(password)
  }
}
