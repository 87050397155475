import {Controller} from "@hotwired/stimulus"
import * as FingerprintJS from '@fingerprintjs/fingerprintjs' // FIXME: this should work, but doesn't... hmmm.

export default class extends Controller {
    static values = {
        debug: {type: Boolean, default: false}
    }
    
    static targets = ['enable', 'fingerprint']

    fingerprint = null

    connect() {
        this.enableTargets.forEach(target => target.disabled = true)
        this.identifyVisitor()
    }

    identifyVisitor() {
        // Initialize an agent at application startup.
        const fpPromise = FingerprintJS.load(); // PRO NOTE: FingerprintJS.load({ apiKey: 'your-public-api-key' })

        fpPromise
            .then(fp => fp.get())
            .then(result => {
                this.fingerprint = result
                this.processFingerprint(this.fingerprint)
            })
    }

    processFingerprint(fp) {
        this.fingerprintTargets.forEach((target) => {
            this.debug_log(`processFingerprint -> setting input[name: '${target.name}' value: '${fp.visitorId}']`)
            target.value = fp.visitorId
        })

        this.enableTargets.forEach((target) => {
            this.debug_log(`processFingerprint -> enabling input[id: '${target.id}' name: '${target.name}']"`)
            target.disabled = false
        })
    }

    fingerprintTargetConnected(element) {
        if (this.fingerprint) {
            this.debug_log(`fingerprintTargetConnected -> setting input[name: '${element.name}' value: '${this.fingerprint.visitorId}']`)
            element.value = this.fingerprint.visitorId
        }
    }

    enableTargetConnected(element) {
        if (this.fingerprint) {
            this.debug_log(`enableTargetConnected -> enabling input[id: '${element.id}' name: '${element.name}']`)
            element.disabled = false
        }
    }

    // TODO: Below are methods from roots_controller.js . To be removed when sublcasses work.

    debug_log(message) {
        if(this.debugValue) {
            console.log(`${this.controllerName}: ${message}`);
        }
    }

    get controllerName() {
        return this.context.identifier
    }
}
