import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="close-modal"
export default class extends Controller {
  static outlets = [ "flowbite-modal" ]

  flowbiteModalOutletConnected(outlet, _element) {
    outlet.hide({})
  }
}
  