import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="attachment-field--progress-modal"
export default class extends Controller {
  static targets = ["cancelBtn", "confirmCancelBtn", "label"]
  static outlets = ["circular-progress", "flowbite-modal"]

  cancelTimeout = null

  connect() {
    this.reset()
  }

  show() {
    this.flowbiteModalOutlet.show()
  }

  hide() {
    this.stopProgress()
    this.flowbiteModalOutlet.hide()
  }

  complete() {
    this.percentProgress(100)
    this.hideCancelButtons()
  }

  reset() {
    this.hideCancelButtons()
    this.circularProgressOutlet.resetProgress()
  }

  start(label) {
    this.labelTarget.textContent = label
    this.cancelTimeout = setTimeout(() => {
      this.cancelBtnTarget.classList.remove("invisible")
    }, 3000)
  }

  percentProgress(progress) {
    this.circularProgressOutlet.percentProgress(progress)
  }

  stopProgress() {
    this.circularProgressOutlet.stopProgress()
  }

  confirmCancel() {
    this.revealConfirmCancelButton()
  }

  hideCancelButtons() {
    this.cancelBtnTarget.classList.add("invisible")
    this.cancelBtnTarget.classList.remove("hidden")
    this.confirmCancelBtnTarget.classList.add("hidden")
  }

  revealConfirmCancelButton() {
    this.cancelBtnTarget.classList.remove("invisible")
    this.cancelBtnTarget.classList.add("hidden")
    this.confirmCancelBtnTarget.classList.remove("hidden")
  }
}
