import { Controller } from "@hotwired/stimulus";

function addClass(element, className) {
  className.split(' ').
    filter((v) => v.length).
    forEach((nm) => element.classList.add(nm))
}

function removeClass(element, className) {
  className.split(' ').
    filter((v) => v.length).
    forEach((nm) => element.classList.remove(nm))
}

export default class extends Controller {
  static targets = ['input', 'selectable'];

  initialize() {
    this._onChange = (() => this.updateSelector());
  }

  connect() {
    this.updateSelector();
  }

  inputTargetConnected(element) {
    element.addEventListener('change', this._onChange);
  }

  inputTargetDisconnected(element) {
    element.removeEventListener('change', this._onChange);
  }

  updateSelector() {
    this.selectableTargets.forEach((reactor) => {
      const sel = reactor.dataset.selectedClass || ''
      const unsel = reactor.dataset.unselectedClass || ''

      const label = reactor.closest('label')
      if (label.control.checked) {
        addClass(reactor, sel)
        removeClass(reactor, unsel)
      } else {
        addClass(reactor, unsel)
        removeClass(reactor, sel)
      }
    });
  }

  toggle(event) {
    const label = event.target.closest('label')
    if (label.control.checked) return;
    this.updateSelector();
  }

  submitForm() {
    this.updateSelector();
    try {
      // wait a tick for the radio selection to update before submitting
      setTimeout(() => {
        this.element.requestSubmit();
      }, 250)
    }
    catch (e) {
      console.error("Cannot do a submit, the data-controller element is not a form which is ok unless you want click to submit on the input targets.");
    }
  }
}
