import { DirectUpload } from "./direct_upload"
import { dispatchEvent } from "./helpers"
import _debug from "debug"

const debug = _debug("direct-upload-controller")

let ids = 0

// Modified version of class in @rails/activestorage to allow for extension and cancelling
export class DirectUploadController {
  constructor(input, file) {
    this.input = input
    this.file = file

    this.cancelled = false
    this.id = ++ids

    this.directUpload = this.createDirectUpload()
    this.dispatch("initialize")
  }

  createDirectUpload() {
    return new DirectUpload(this.file, this.url, this)
  }

  start(callback) {
    this.debug("Starting upload")

    const hiddenInput = document.createElement("input")
    hiddenInput.type = "hidden"
    hiddenInput.name = this.input.name
    hiddenInput.dataset.directUploadId = this.directUpload.id
    this.input.insertAdjacentElement("beforebegin", hiddenInput)

    this.dispatch("start")

    this.debug("Creating direct upload")
    this.directUpload.create((error, attributes) => {
      this.debug("Direct upload ended", error)
      if (error || this.cancelled) {
        hiddenInput.parentNode.removeChild(hiddenInput)
        this.dispatchError(error)
      } else {
        hiddenInput.value = attributes.signed_id
      }

      this.dispatch("end")

      if (!this.cancelled) {
        callback(error)
        this.debug("Upload finished")
      }
    })
  }

  cancel() {
    this.debug("Cancelling upload")
    this.cancelled = true
    this.directUpload.cancel()
    if (this.upload) {
      this.upload.removeEventListener("progress", this.uploadRequestDidProgress)
    }
  }

  uploadRequestDidProgress = (event) => {
    const progress = event.loaded / event.total * 100
    if (progress) {
      this.dispatch("progress", { progress })
    }
  }

  get url() {
    return this.input.getAttribute("data-direct-upload-url")
  }

  dispatch(name, detail = {}) {
    if (this.cancelled) return

    detail.file = this.file
    detail.id = this.directUpload.id
    return dispatchEvent(this.input, `direct-upload:${name}`, { detail })
  }

  dispatchError(error) {
    if (this.cancelled) return

    const event = this.dispatch("error", { error })
    if (!event.defaultPrevented) {
      alert(error)
    }
  }

  debug(message, ...args) {
    const newArgs = [`(${this.id})`]
    if (this.cancelled)
      newArgs.push("[CANCELLED]")
    newArgs.push(message)
    debug(...newArgs, ...args)
  }

  // DirectUpload delegate

  directUploadWillCreateBlobWithXHR(xhr) {
    this.dispatch("before-blob-request", { xhr })
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.dispatch("before-storage-request", { xhr })
    this.upload = xhr.upload
    this.upload.addEventListener("progress", this.uploadRequestDidProgress)
  }
}
