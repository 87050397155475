export function findTarget(root, target, cb) {
  const el = root.querySelector(`[data-target='${target}']`)
  if (el) {
    cb(el)
  } else {
    console.error("Element wth target not found:", target)
  }
}

export function setTextContentOnTarget(root, target, text) {
  findTarget(root, target, (el) => el.textContent = text)
}

export function removeClassOnTarget(root, target, className) {
  findTarget(root, target, (el) => el.classList.remove(className))
}

export function addClassOnTarget(root, target, className) {
  findTarget(root, target, (el) => el.classList.add(className))
}

export function setWidthOnTarget(root, target, width) {
  findTarget(root, target, (el) => el.style.width = width)
}
